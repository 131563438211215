import { styled } from '@mui/system'
import { type PageProps } from 'gatsby'
import * as React from 'react'
import { EngageRings } from '../../../components/EngageRings'
import { Layout } from '../../../components/Layout'
import { SEO } from '../../../components/SEO'
import { Txt } from '../../../components/Txt'
import { useEngageRingParams } from '../../../hooks/useEngageRingParams'

const DESCRIPTION =
    '格安の婚約指輪（10万円以上）は卸売価格だから実現できる高品質で低価格なエンゲージリングならミスプラチナでご購入いただけます。'
export const Head = () => (
    <SEO
        title="安い婚約指輪・激安エンゲージリング（10万円以上）をお探しなら｜ミスプラチナ"
        description={DESCRIPTION}
        page="engagering/price/10万円以上"
    />
)

const TenPage: React.FC<PageProps> = ({ location }) => {
    const title = (
        <H1>
            <Txt size="l">10万円以上</Txt>
            <Txt size="s">の婚約指輪を探す</Txt>
        </H1>
    )
    const params = useEngageRingParams(location.search)
    return (
        <Layout
            breadcrumbs={[
                { name: '安い婚約指輪・エンゲージリング', path: 'engagering/' },
                { name: '値段から探す', path: 'engagering/price/' },
                { name: '10万円台以上' },
            ]}
        >
            <EngageRings
                title={title}
                description={DESCRIPTION}
                {...params}
                ten
                price
            />
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '0 0 8px',
    lineHeight: 1,
})

export default TenPage
